import * as MasterService from "@/apiServices/MastersServices";
import * as VendorServices from "@/apiServices/VendorServices";
import { debounce } from "@/utils/helpers";

export default {
  namespaced: true,
  state: {
    type: [],
    genre: [],
    age: [],
    language: [],
    director: [],
    cast: [],
    country: [],
    image_format: [],
    sound_format: [],
    aspect_ratio: [],
    label: [],
    media_type: [],
    distributor: [],
    pricing_code: [],
    contract_type: [],
    revenue_share: [],
    currency: [],
    milestone: [],
    bumper: [],
    contact_type: [],
    interest: [],
    // Tree Data
    rights_tree: [],
    territory_tree: [],
    vendors: [],
    movies: [],
    territory: [],
    rights: [],
    sub_right: [],
    categorys: [],
    file_type: [],
    show_code: [],
    pricing_method: [],
    screen_definition: [],
    contribution_masters: [],
    material_physical: [],
    material_digital: [],
    material_language_version: [],
    language_booking_code: [],
    depot_portal: [],
  },

  getters: {
    allCasts: (state) => state.cast,
  },

  mutations: {
    SET_LABEL_MASTER(state, value) {
      const { key, val } = value;
      state[key] = val;
    },
    SET_LABEL_TREE_MASTER(state, value) {
      const { key, val } = value;
      state[key] = val;
    },
    SET_TERRITORY_TREE(state, value) {
      state.territory_tree = value
    },
    SET_RIGHT_TREE(state, value) {
      state.rights_tree = value
    },
    SET_VENDORS_MASTER(state, value) {
      state.vendors = value;
    },
    SET_MOVIES_MASTER(state, value) {
      state.movies = value;
    },
    SET_CONTRACT_TYPE_MASTER(state, value) {
      state.contract_types = value;
    },
  },
  actions: {
    setLabelMaster: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_LABEL_MASTER", { key: payload.key, val: [] });
      return MasterService.getAllData({ master: payload.key, deleted: "0" })
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_LABEL_MASTER", {
              key: payload.key,
              val: response.data.data.rows,
            });
          } else {
            console.log(response.data);
          }
        })
        .catch((err) => {
          console.log("Error in getting master data ", err);
        });
    },

    setLabelTreeMaster: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_LABEL_TREE_MASTER", { key: payload.key, val: [] });
      return MasterService.getAllTreeDataActive(
        payload.master,
        payload.sub_master
      )
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_LABEL_TREE_MASTER", {
              key: payload.key,
              val: response.data.data,
            });
          } else {
            console.log(response.data);
          }
        })
        .catch((err) => {
          console.log("Error in getting tree master data ", err);
        });
    },

    setVendorsMaster: debounce(async ({ dispatch, commit, state }, payload) => {
      await commit("SET_VENDORS_MASTER", []);
      return VendorServices.getAllVendor({ deleted: false ,limit:50,...payload})
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_VENDORS_MASTER", response.data.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((err) => {
          console.log("Error in getting vendors master data ", err);
        });
    }),

    setMoviesMaster: debounce(async ({ dispatch, commit, state }, payload) => {
      await commit("SET_MOVIES_MASTER", []);
      return MasterService.getAllMovies({ archived: false, limit:50, ...payload })
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_MOVIES_MASTER", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((err) => {
          console.log("Error in getting movies master data ", err);
        });
    }),

    setTerritoryMaster: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_TERRITORY_TREE", []);
      return MasterService.getMasterList({ list_route: `territory-tree`, params: payload })
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_TERRITORY_TREE", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((err) => {
          console.log("Error in getting territory master data ", err);
        });
    },

    setRightMaster: async ({ dispatch, commit, state }, payload) => {
      await commit("SET_RIGHT_TREE", []);
      return MasterService.getMasterList({ list_route: `right-tree`, params: payload })
        .then(async (response) => {
          if (response.data.status) {
            await commit("SET_RIGHT_TREE", response.data.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((err) => {
          console.log("Error in getting right master data ", err);
        });
    }
  },
};
